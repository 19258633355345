import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { WpService } from '@core/services/wp.service';
import { ApiService } from '@core/services/api.service';
import { ModalService } from '@shared/components/ezz-modal-module';
import { TagCommanderService } from 'ngx-tag-commander';

import GeocoderResult = google.maps.GeocoderResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

declare var tC: any;
declare var adobe: any;

interface InputOutputOptions {
    avoidTranslation?: boolean;
    decimals?: number;
    currency?: boolean;
    unit?: 'panelPower' | 'power' | 'energy' | 'years' | '%' | 'surface';
}

type InputOutputType = 'text' | 'number' | 'array-text' | 'array-number' | 'boolean';

type location = {
    provinciaBreve: string;
    provinciaStesa: string;
    cap: string;
    coperta: string;
 };

@Injectable({
    providedIn: 'root'
})

export class GeneralInfoService {
    campaignHash: string;
    userState: string;
    formattedAddress: string;
    address: string;
    place: google.maps.places.PlaceResult;
    latitude: number;
    longitude: number;
    province: string;
    addressComponents = {
        streetNumber: '',
        streetName: '',
        city: '',
        zip: '',
        countryCode: '',
        country: ''
    };

    urlPlace: GeocoderResult;
    profitabilityStorage: any = {};
    profitabilityWithoutBatteriesStorage: any = {};
    profitabilityWithBatteriesStorage: any = {};

    //General Variables
    consumption: number;
    priceTableTag: string;
    priceTableTagBatteries: string;
    dataForm: any = {};
    isLead: boolean = false;
    breakPointMobile: number = 740;

    //Drawing and surface    
    area: number;
    surface: number = undefined;
    minLowArea: number;
    maxHighArea: number;
    coords: any = undefined;

    //HouseInformation
    surfaceType: string;
    numberChimneys: number = 0;
    numberAntennas: number = 0;
    numberWindows: number = 0;
    numberWaterCollectors: number = 0;

    //Show/Hide forms
    isRegister: boolean = false;
    isLogin: boolean = false;
    isLostPassword: boolean = false;
    isCreatePassword: boolean = false;
    thanksView: boolean = false;
    successLostPassword: boolean = false;

    //Redirections
    preflight: string;
    opportunityId: string;
    pushCTAHouseInformation: boolean = false;
    pushCTAResults: boolean = false;

    //Sections
    sectionMap: string;
    sectionHouse: string;
    sectionSimulation: string;
    sectionRegister: string;    
    sectionLostPassword: string;
    sectionThanks: string;

    //Battery
    battery: boolean = false;
    batteryCapacity: number;
    batteryCapacityCompare: number;

    //Steps Header
    homepage: boolean = true;
    step1: boolean = false;
    step2: boolean = false;
    step3: boolean = false;
    step4: boolean = false;

    //Show/Hide Popup
    showPopup: boolean = false;
    viewModalLead: boolean = false;

    //If get all simulator information
    loaderSimulator: boolean = true;
    loaderResults: boolean = true;

    //isSimulator section
    isSimulator: boolean;

    //Locations CSV
    locationsStrings: any = [];
    locations: Array<location> = [];
    invalidCap: boolean = false;

    //Slider Price
    minConsumption: number;
    maxConsumption: number;
    percentageRange: number;
    percentageFinance: number;
    maxPercetageSliderConsumption : number;
    minkWh: number;
    maxkWh: number;
    consumptionInvalid: boolean = false;

    //Results Page
    finance: boolean = false;
    financingYears: number;
    months: number;
    initialModule: number;
    module: number;
    consigliato: number;
    optimizer: boolean = false;
    wallbox: boolean = false;
    eps: boolean = false;
    savingMonth: number;
    energySurplus: number;

    //Modals
    modalCardId: string;
    modalCardTitle: string;
    modalCardText: string;

    modalBatteryId: string;
    modalBatteryTitle: string;
    modalBatteryText: string;

    viewModalExit: boolean = false;
    showPopUp: boolean = true;
    mousePos: number;
    numberAntiExit: number;

    viewModalResume: boolean = false;

    //Register
    selectedPrivacy: boolean = false;
    nameError: boolean = false;
    entryName: boolean = false;
    surnameError: boolean = false;
    entrySurname: boolean = false;
    phoneError: boolean = false;
    entryPhone: boolean = false;
    emailError: boolean = false;
    entryEmail: boolean = false;
    showErrorCode: boolean = false;
    errorCode: string;
    userIP: string = null;
    statusAction: string;

    //TagCommander
    envWork: boolean = Boolean(JSON.parse(environment.production));
    envWorkPre: string;
    envWorkPro: string;
    basketId: string;
    orderB2: string;
    orderListino: string;
    tcDataSegmentID: string;
    pageCategory: string;
    author: string;
    gClid: string;
    textYes: string;
    textNo: string;
    tc: any;
    envChannelDesktop: string;
    envChannelMobile: string;

    constructor (
        public http: HttpClient,
        public router: Router,
        public wp: WpService,
        public apiService: ApiService,
        public modalService: ModalService,
        private tcService: TagCommanderService
    ) {}

    loadContainerTagCommander() {
        if (Boolean(JSON.parse(environment.production))) {
            let number = Math.ceil(Math.random()*1000);
            Promise.all([
                this.tcService.addContainer('3119_1', 'https://cdn.tagcommander.com/3119/tc_Sorgenia_1.js?cachebuster=' + (number + 55), 'head'),
                this.tcService.addContainer('3119_3', 'https://cdn.tagcommander.com/3119/tc_Sorgenia_3.js?cachebuster=' + number, 'body'),
                this.tcService.addContainer('3119_6', 'https://cdn.tagcommander.com/3119/tc_Sorgenia_6.js?cachebuster=' + (number + 25), 'body'),                
                this.tcService.addContainer('3119_7', 'https://cdn.tagcommander.com/3119/tc_Sorgenia_7.js?cachebuster=' + (number +110), 'body')
              ]).then(() => {})
        } else {
            this.tcService.setDebug(true);

            let number = Math.ceil(Math.random()*1000);            
            Promise.all([
                this.tcService.addContainer('3119_1', 'https://cdn.tagcommander.com/3119/uat/tc_Sorgenia_1.js?cachebuster=' + (number + 55), 'head'),
                this.tcService.addContainer('3119_3', 'https://cdn.tagcommander.com/3119/uat/tc_Sorgenia_3.js?cachebuster=' + number, 'body'),
                this.tcService.addContainer('3119_6', 'https://cdn.tagcommander.com/3119/uat/tc_Sorgenia_6.js?cachebuster=' + (number + 25), 'body'),                
                this.tcService.addContainer('3119_7', 'https://cdn.tagcommander.com/3119/uat/tc_Sorgenia_7.js?cachebuster=' + (number + 105), 'body')
              ]).then(() => {})
        }

        this.tcService.trackRoutes(true);
    }

    /**
     * Function getBasketId
     */
    getBasketId() {
        let basket_id = localStorage.getItem('basket_id');
        let uid = this.uid();

        if(basket_id === null) {
            localStorage.setItem('basket_id', uid);
            this.basketId = uid;
        } else {
            this.basketId = basket_id;
        }
    }

    uid() {
        return 'B' + Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
    
    /**
     * Function Adobe Target
     * @param pageName 
     */
    targetView(pageName) {
        if (typeof adobe?.target?.triggerView === 'function') {
            adobe.target.triggerView(pageName);
        }
    }

    /*
    * Function for active events TagCommanderService
    */
    setTC() {
        const tcLoader = setInterval(() => {
            if(typeof tC === "object") {
                clearInterval(tcLoader);
            }
        }, 1000);
    }
    
    activeEventAddressCoverageVerifyError(e) {
        tC.event.address_coverage_verify_error(this, { errors: e });
    }
    
    activeEventNavigationClick(id) {
        tC.event.navigation_click(this, { path: id });
    }

    activeEventUpSelling(s) {
        tC.event.upselling_typ_click(this, { solutions: s });
    }

    modalRicontattoLead() {
        !this.viewModalExit ? tC.event.modal_ricontatto_lead(this, {}) : tC.event.modal_exitpanel_lead(this, {});
    }

    activeEventUpSellingClick(v) {
        tC.event.upselling_vas_click(this, { vas: v });
    }   

    roofDrawingError(e) {
        tC.event.roof_drawing_error(this, { errors: e });
    }

    activeEventFormError(formName, formId) {
        var invalidInputForm = this.getFormErrors(formId);
        !this.viewModalExit ? tC.event.form_error_submit(this, { form: formName, invalidInput: invalidInputForm}) : tC.event.form_exitpanel_error_submit(this, { form: formName, invalidInput: invalidInputForm});
    }

    getFormErrors(form) {
        const invalid = [];
        const controls = form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    /**
     * Function to set tc vars
     */
    getEnvChannel(): string {
        return screen.width < this.breakPointMobile ? this.envChannelMobile : this.envChannelDesktop;
    }

    /*
    Register
    */
    checkPrivacy() {
        this.selectedPrivacy = !this.selectedPrivacy;
    }

    /*
    Slider Price/Finance
    */
    setPercentage() {
        this.percentageRange = (this.consumption - 20) / 2.8;
        this.percentageFinance = ((this.financingYears - 3) * 100) / 7;
    }

    /*
    kWh Slidser Price
    */
    setkWh(v) {
        let coefficient = parseFloat(this.wp.dataVariables?.coefficient);
        let nFixed = parseFloat(this.wp.dataVariables?.nfixed);
        let nsubtract = parseFloat(this.wp.dataVariables?.nsubtract);

        let c = coefficient * (v - nsubtract);

        this.minkWh = c - nFixed;
        this.maxkWh = c + nFixed;
    }

    /*
    Slider Price
    */
    setConsumption(v) {
        if(v < this.minConsumption || v > this.maxConsumption) {
            this.consumptionInvalid = true;
        } else {
            this.consumptionInvalid = false;
            this.consumption = Number(v);

            //TagCommander
            this.tcService.setTcVar('order_spesa_luce', v + this.wp.dataThemeExtra?.euro);
        }        

        this.setPercentage();
        this.setkWh(this.consumption);
    }

    /*
    Function for get all locations by CSV File Uploads
    */
    getTotalLocations(): void {        
        this.http.get(this.wp.dataCSV.url, { responseType: 'text' }).subscribe(data => {
            
            const list = data.split('\n');            
            list.forEach( (e, index) => {        
                this.locationsStrings.push(e.split(';'));
                if(this.locationsStrings[index][3] == "SI\r") {
                    this.locations.push(
                        {
                            'provinciaBreve': this.locationsStrings[index][0],
                            'provinciaStesa': this.locationsStrings[index][1],
                            'cap': this.locationsStrings[index][2],
                            'coperta': this.locationsStrings[index][3]
                        }
                    );    
                }                            
            });
        });
    }

    /*
    * Get true/false if ZIP code is in locations
    */
    getLocations() {
        let selectedCap = this.locations.find(element => element.cap == this.addressComponents.zip);
        this.invalidCap = selectedCap == undefined ? true : false;
    }

    /*
    * Initialize variables to simulator
    */
    initializeGeneralData() {
        //Variables
        this.consumption = this.wp.dataVariables?.consumption;
        this.minLowArea = parseInt(this.wp.dataVariables?.minlowarea);
        this.maxHighArea = parseInt(this.wp.dataVariables?.maxhigharea);
        this.minConsumption = this.wp.dataVariables?.minconsumption;
        this.maxConsumption = this.wp.dataVariables?.maxconsumption;
        this.maxPercetageSliderConsumption = this.wp.dataVariables?.maxpercetagesliderconsumption;
        this.financingYears = this.wp.dataVariables?.financingyears;
        this.months = this.wp.dataVariables?.months;
        this.initialModule = this.wp.dataVariables?.initialmodule;
        this.module = this.wp.dataVariables?.module;
        this.consigliato = this.wp.dataVariables?.consigliato;
        this.batteryCapacityCompare = 4.8;
        this.numberAntiExit = 0;
        
        //Sections
        this.sectionMap = 'map';
        this.sectionHouse = 'house-information';       
        this.sectionSimulation = 'results-page'; 
        this.sectionRegister = 'riepilogo';   
        this.sectionThanks = 'grazie-budget';     
        this.sectionLostPassword = 'lost-password';  

        //Get User IP
        this.getUserIP();

        //TagCommander
        this.envWorkPre = this.wp.dataTagCommander?.envworkpre;
        this.envWorkPro = this.wp.dataTagCommander?.envworkpro;
        this.orderB2 = this.wp.dataTagCommander?.orderb2;
        this.orderListino = this.wp.dataTagCommander?.orderlistino;
        this.tcDataSegmentID = this.wp.dataTagCommander?.tcdatasegmentid;
        this.pageCategory = this.wp.dataTagCommander?.pagecategory;
        this.author = this.wp.dataTagCommander?.author;
        this.textYes = this.wp.dataTagCommander?.textyes;
        this.textNo = this.wp.dataTagCommander?.textno;
        this.envChannelDesktop = this.wp.dataTagCommander?.envchanneldesktop;
        this.envChannelMobile = this.wp.dataTagCommander?.envchannelmobile;
        this.gClid = " ";
    }

    /*
    * Initialize data Register
    */
    initializeRegisterData() {
        this.selectedPrivacy = false;
        this.nameError = false;
        this.entryName = false;
        this.surnameError = false;
        this.entrySurname = false;
        this.phoneError = false;
        this.entryPhone = false;
        this.emailError = false;
        this.entryEmail = false;
        this.showErrorCode = false;
    }

    /*
    Get User IP
    */
    getUserIP() {
        this.http.get<{ip:string}>('https://jsonip.com')
        .subscribe( data => {
            this.userIP = data.ip;
        })
    }

    /*
    * Set Steps Header
    */
    setSteps(valueStep1, valueStep2, valueStep3, valueStep4) {
        this.step1 = valueStep1;
        this.step2 = valueStep2;
        this.step3 = valueStep3;
        this.step4 = valueStep4;
    }

    /*
    * Set advised module
    */
    setConsigliato(module, neededSurface) {
        let available_surface = this.profitabilityStorage?.outputs?.available_surface;
        let modules: { modules_number: number, needed_surface: number } [] = [
            {
                modules_number: module,
                needed_surface: neededSurface
            }
        ];   

        this.profitabilityStorage?.outputs?.additional_offers.forEach(o => {
            modules.push(o);
        });

        return this.getMax(modules, available_surface);
    }

    /*
    * Get max number
    */
    getMax(modules, available_surface) {
        var r = modules[0].modules_number;

        if(available_surface == this.minLowArea)
            return r;

        modules.forEach(module => {
            if (available_surface > module.needed_surface) {
                if (module.modules_number > r) {
                    r = module.modules_number;
                }
            }
        });

        return r;
    }

    /*
    Return Scroll 0
    */
    scroll0() {
        window.scrollTo(0,0);
    }

    /**
     * Return data for profitability
     */
    getDataForProfitability(module: number | null): any {
        // TODO: change this
        const data = {  
            financing: true,          
			house_consumption: this.consumption,
		    installation_type: this.wp.dataVariables?.installationtype,		    
		    latitude: this.latitude,
		    longitude: this.longitude,
			module_name: this.orderListino,
			module_packages: [[6, 10, 14]],
			modules_number: module,
			number_antennas: this.numberAntennas,
			number_chimneys: this.numberChimneys,
			number_of_installments: this.financingYears*this.months,
			number_water_collectors: this.numberWaterCollectors,
			number_windows: this.numberWindows,
		    opportunity_type_id: environment.opportunityTypeId,
			performance_ratio: 0.8,			
            price_table_tag: this.priceTableTag, 
			shadow_losses: 0.04,
			surface: this.surface,
		    surface_type: this.surfaceType,
            charge_curve_type: 'residential'
        };

        return data;
    }

    /**
     * Return data for profitability Without Batteries
     */
    getDataWithoutBatteriesProfitability(module): any {
        return {
            ...this.getDataForProfitability(module),  
            price_table_tag: this.priceTableTag,                
            with_batteries: false
        };
    }

    /**
     * Return data for profitability With Batteries
     */
    getDataWithBatteriesProfitability(module): any {
        return {
            ...this.getDataForProfitability(module), 
            price_table_tag: this.priceTableTagBatteries, 
            with_batteries: true
        };
    }

    /**
     * Function to get Address
     */
    getAddress(): string {
        return this.addressComponents.streetName ? this.addressComponents.streetNumber ? this.addressComponents.streetName + ' ' + this.addressComponents.streetNumber : this.addressComponents.streetName : this.formattedAddress;
    }

    /**
     * Function that parse profitability data to inputs and outputs
     */
    parseInputsAndOutputs(profitabilityData: any): { inputs: any, outputs: any} {
        let inputs = profitabilityData.inputs;
        let outputs;

        inputs = {
            ...inputs,
            house_consumption: this.buildTypeStructureToPlatform(Number(this.consumption), 'number', { decimals: 2, currency: true })
        }
        
        outputs = {     
            batteries: this.buildTypeStructureToPlatform(this.battery ? this.wp.dataThemeExtra?.include_text : this.wp.dataThemeExtra?.not_include_text, 'text'),
            optimizers: this.buildTypeStructureToPlatform(this.optimizer ? this.wp.dataThemeExtra?.include_text : this.wp.dataThemeExtra?.not_include_text, 'text'),
            wallbox: this.buildTypeStructureToPlatform(this.wallbox ? this.wp.dataThemeExtra?.include_text : this.wp.dataThemeExtra?.not_include_text, 'text'),
            eps: this.buildTypeStructureToPlatform(this.eps ? this.wp.dataThemeExtra?.include_text : this.wp.dataThemeExtra?.not_include_text, 'text'),
            battery_storage_capacity: this.buildTypeStructureToPlatform(this.battery ? this.batteryCapacity : 0, 'number', { decimals: 2, currency: false, unit: 'energy' }),
            scambio_sul_posto: this.buildTypeStructureToPlatform(profitabilityData.outputs?.self_consumption?.ssp[1], 'number', { decimals: 2, currency: true }),
            total_savings_month: this.buildTypeStructureToPlatform(this.savingMonth, 'number', { decimals: 2, currency: true }),
            self_consumption: this.buildTypeStructureToPlatform(profitabilityData.outputs?.self_consumption?.yearly_self_consumption_percentage, 'number', { decimals: 0, currency: false, unit: '%' }),
            self_consumption_percentage: this.buildTypeStructureToPlatform(profitabilityData.outputs?.self_consumption?.yearly_self_consumption_percentage, 'number', { decimals: 0, currency: false, unit: '%' }),
            self_consumption_percentage_battery_no_battery: this.buildTypeStructureToPlatform(!this.battery ? this.profitabilityWithoutBatteriesStorage.outputs?.self_consumption?.yearly_self_consumption_percentage : 0, 'number', { decimals: 0, currency: false, unit: '%' }),
            self_consumption_percentage_battery: this.buildTypeStructureToPlatform(this.battery ? this.profitabilityWithBatteriesStorage.outputs?.self_consumption?.yearly_self_consumption_percentage : 0, 'number', { decimals: 0, currency: false, unit: '%' }),
            installation_power: this.buildTypeStructureToPlatform(profitabilityData.outputs.installed_power / 1000, 'number', { decimals: 2, currency: false, unit: 'panelPower' }),
            production_annual: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.production[1] / 1000, 'number', { decimals: 0, currency: false, unit: 'energy' }),
            total_price: this.buildTypeStructureToPlatform(profitabilityData.outputs.installation.price_with_vat, 'number', { decimals: 0, currency: true }),
            total_price_discount: this.buildTypeStructureToPlatform(profitabilityData.outputs.installation.price_without_vat, 'number', { decimals: 0, currency: true }),
            payback: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.payback, 'number', { decimals: 0, currency: false, unit: 'years' }),
            self_consumption_saving: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.self_consumption_saving.self_consumption_saving_total, 'number', { decimals: 0, currency: true }),
            house_consumption_year_1: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.direct_consumption[1], 'number', { decimals: 0, currency: true }),
            house_consumption_25_years: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.direct_consumption[25], 'number', { decimals: 0, currency: true }),
            total_expenses_year_1: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.total_expenses[1], 'number', { decimals: 0, currency: true }),
            total_expense_25_years: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.total_expenses[25], 'number', { decimals: 0, currency: true }),
            total_savings_year_1: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.total_savings[1], 'number', { decimals: 0, currency: true }),
            total_savings_25_years: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.total_saving, 'number', { decimals: 0, currency: true }),
            yearly_self_consumption_percentage: this.buildTypeStructureToPlatform(profitabilityData.outputs?.self_consumption?.self_consumption_percentage, 'number', { decimals: 0, currency: false, unit: '%' }),
            car_kilometers: this.buildTypeStructureToPlatform(profitabilityData.outputs.environment.car_kilometers, 'number', { decimals: 0, currency: false }),
            planted_trees: this.buildTypeStructureToPlatform(profitabilityData.outputs.environment.planted_trees, 'number', { decimals: 0, currency: false }),
            co2: this.buildTypeStructureToPlatform(profitabilityData.outputs.environment.co2, 'number', { decimals: 0, currency: false }),
            specific_production: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.production[1] / 1000, 'number', { decimals: 0, currency: false, unit: 'energy' }),
            surface_used_by_modules: this.buildTypeStructureToPlatform(profitabilityData.outputs.needed_surface, 'number', { decimals: 0, currency: false }),
            module_number: this.buildTypeStructureToPlatform(profitabilityData.outputs.module_number, 'number', { decimals: 0, currency: false }),       
            production: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.production, 'array-number', { decimals: 0, currency: false, unit: 'energy' }),
            total_savings: this.buildTypeStructureToPlatform(profitabilityData.outputs.self_consumption.total_savings, 'array-number', { decimals: 2, currency: true }),
            roof_shape: this.buildTypeStructureToPlatform(this.surfaceType, 'text'),
            surface: this.buildTypeStructureToPlatform(profitabilityData.outputs?.available_surface, 'number', { decimals: 0, currency: false }),    
            chimney: this.buildTypeStructureToPlatform(this.numberChimneys, 'number', { decimals: 0, currency: false }),    
            windows: this.buildTypeStructureToPlatform(this.numberWindows, 'number', { decimals: 0, currency: false }),    
            antennas: this.buildTypeStructureToPlatform(this.numberAntennas, 'number', { decimals: 0, currency: false }),    
            water_panels: this.buildTypeStructureToPlatform(this.numberWaterCollectors, 'number', { decimals: 0, currency: false }),  
            monthly_fee: this.buildTypeStructureToPlatform(profitabilityData.outputs.financing.monthly_financing, 'number', { decimals: 2, currency: true }),
            financing_months: this.buildTypeStructureToPlatform(this.financingYears*this.months, 'number', { decimals: 2, currency: true }),
            financing_years: this.buildTypeStructureToPlatform(this.financingYears, 'number', { decimals: 2, currency: true }),
            financing_with_interest: this.buildTypeStructureToPlatform(profitabilityData.outputs.financing.financed_total, 'number', { decimals: 2, currency: true }),
            financing_tan: this.buildTypeStructureToPlatform(profitabilityData.outputs.financing.tan*100, 'number', { decimals: 2, currency: true }),
            financing_taeg: this.buildTypeStructureToPlatform(profitabilityData.outputs.financing.taeg*100, 'number', { decimals: 2, currency: true })
        };

        return {
            inputs,
            outputs
        };
    }

    /**
     * Function that build input and output properties with correct format
     */
    buildTypeStructureToPlatform(value: any, type: InputOutputType, options?: InputOutputOptions) {
        return {
            value,
            type,
            ...options
        };
    }

    /**
     * Function to extract the address taking the address components given for places of google
     * @param address google address_components
     */
    extractAddress(address: GeocoderAddressComponent[]): any {
        const self = this;

        const posStreetNumber = address.find(w => {
            return Object.values(w.types).includes('street_number');
        });

        const posStreetName = address.find(w => {
            return Object.values(w.types).includes('route');
        });

        const posCity = self.extractCity(address);

        const posZip = address.find(w => {
            return Object.values(w.types).includes('postal_code');
        });

        const posCountryCode = address.find(w => {
            return Object.values(w.types).includes('country') && Object.values(w.types).includes('political');
        });

        const posCountry = address.find(w => {
            return Object.values(w.types).includes('country') && Object.values(w.types).includes('political');
        });

        return {
            streetNumber: posStreetNumber ? posStreetNumber.long_name : null,
            streetName: posStreetName ? posStreetName.long_name : null,
            city: posCity ? posCity.long_name : null,
            zip: posZip ? posZip.long_name : null,
            countryCode: posCountryCode ? posCountryCode.short_name : null,
            country: posCountry ? posCountry.long_name : null
        };
    }

    /**
     * Function that extract city, if not found locality search in administrative_area_level_2 or administrative_area_level_3
     * @param address
     */
    extractCity(address: GeocoderAddressComponent[]): GeocoderAddressComponent {
        let city = address.find(w => {
            return Object.values(w.types).includes('locality') && Object.values(w.types).includes('political');
        });

        if (!city) {
            city = address.find(w => {
                return Object.values(w.types).includes('administrative_area_level_2') && Object.values(w.types).includes('political');
            });

            if (!city) {
                city = address.find(w => {
                    return Object.values(w.types).includes('administrative_area_level_3') && Object.values(w.types).includes('political');
                });
            }
        }

        return city;
    }

    /**
     * Type Pay
     */
    setTypePay(type) {
        let text;
        text = type ? this.wp.dataSimulationStep3?.title_finanzamento : this.wp.dataSimulationStep3?.title_pagamento_directto;
        this.finance = type;
        this.battery ? this.getDataProfitabilityWithBatteries(this.module) : this.getDataProfitabilityWithoutBatteries(this.module);

        //TagCommander
        this.tcService.setTcVar('order_payment_methods', this.finance ? this.wp.dataSimulationStep3?.finance?.title : this.wp.dataSimulationStep3?.payment?.title);
        tC.event.payment_mode_click(this, { paymentMode: text });
    }

    /**
     * Get Data API SIMULATION
     */
    getDataProfitabilityWithoutBatteries(module: number | null, dataLoad?: boolean) {
        //this.loaderResults = true;
        this.setTableTag();
        const data = this.getDataWithoutBatteriesProfitability(module);
        
        this.apiService.getProfitability(data).subscribe({
            next: (response: any) => {
                this.profitabilityWithoutBatteriesStorage = response;  
                if(module != null ) {
                    this.setDataResults(module);
                }

                if(dataLoad) {
                    this.setDataResults(null);
                }
                this.loaderResults = false;
            },        
            error: (e) => {},        
            complete() {},
        });        
    }

    getDataProfitabilityWithBatteries(module: number | null) {
        //this.loaderResults = true;
        this.setTableTag();
        const data = this.getDataWithBatteriesProfitability(module);       

        this.apiService.getProfitability(data).subscribe({
            next: (response: any) => {
                this.profitabilityWithBatteriesStorage = response;
                if(module != null) {
                    this.setDataResults(module);
                }
                this.loaderResults = false;
            },        
            error: (e) => {},        
            complete() {},
        });
    }

    /**
     * Data Results when API call
     */
    setDataResults(module: number | null) {
        this.profitabilityStorage = this.battery ? this.profitabilityWithBatteriesStorage : this.profitabilityWithoutBatteriesStorage;
        this.batteryCapacity = (this.profitabilityWithBatteriesStorage?.inputs?.batteries_used_info[0]?.capacity * this.profitabilityWithBatteriesStorage?.inputs?.batteries_used_info[0]?.quantity) / 1000;  
        
        if(!this.battery) {
            this.eps = false;
        }

        this.savingMonth = (this.profitabilityStorage?.outputs?.self_consumption?.total_savings[1] - this.profitabilityStorage?.outputs?.self_consumption?.ssp[1]) / this.months;
        this.energySurplus = (this.profitabilityStorage?.outputs?.self_consumption?.excess_wh_total[1] - this.profitabilityStorage?.outputs?.self_consumption?.bought_wh_total[1]) / 1000;

        this.module = this.profitabilityStorage?.outputs?.module_number;

        this.consigliato = this.setConsigliato(this.module, this.profitabilityStorage?.outputs?.needed_surface); 
        this.initialModule =  module == null ? this.module > this.consigliato ? this.consigliato : this.module : this.initialModule;
        this.setDataModalBattery();    
    }

    /**
     * Data Results Modal Battery
     */
    setDataModalBattery() {
        if(this.batteryCapacity === this.batteryCapacityCompare) {
            this.modalBatteryId = this.wp.dataSimulationStep3.modal_battery_48.id;
            this.modalBatteryTitle = this.wp.dataSimulationStep3.modal_battery_48.title;
            this.modalBatteryText = this.wp.dataSimulationStep3.modal_battery_48.content;
        } else {
            this.modalBatteryId = this.wp.dataSimulationStep3.modal_battery_96.id;
            this.modalBatteryTitle = this.wp.dataSimulationStep3.modal_battery_96.title;
            this.modalBatteryText = this.wp.dataSimulationStep3.modal_battery_96.content;
        }
    }

    /**
     * Table Tag when API call
     */
    setTableTag() {
        let flat = this.surfaceType === this.wp.dataSimulationStep2.select_roof_house.roof_4?.id ? true : false;

        if(this.eps && this.optimizer && flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_24;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_24;
        }            
            
        if(this.eps && this.optimizer && !flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_23;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_23;
        }            
        
        if(this.eps && !this.optimizer && flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_22;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_22;
        }            

        if(this.eps && !this.optimizer && !flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_21;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_21;
        }            

        if(!this.eps && this.optimizer && flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_16;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_20;
        }            

        if(!this.eps && this.optimizer && !flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_15;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_19;
        }            

        if(!this.eps && !this.optimizer && flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_14;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_18;
        }            

        if(!this.eps && !this.optimizer && !flat && this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_13;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_17;    
        }                   

        if(this.eps && this.optimizer && flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_12;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_12;
        }            

        if(this.eps && this.optimizer && !flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_11;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_11;
        }            

        if(this.eps && !this.optimizer && flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_10;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_10;
        }            

        if(this.eps && !this.optimizer && !flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_9;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_9; 
        }                  

        if(!this.eps && this.optimizer && flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_4;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_8;
        }            

        if(!this.eps && this.optimizer && !flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_3;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_7;
        }            

        if(!this.eps && !this.optimizer && flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_2;
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_6;
        }            

        if(!this.eps && !this.optimizer && !flat && !this.wallbox) {
            this.priceTableTag = this.wp.dataTableTags.tag_1; 
            this.priceTableTagBatteries = this.wp.dataTableTags.tag_5;
        }            
    }

    /**
     * Redirect to home
     */
    backHome(): void {
        window.location.href="/";
    }

    /**
    * Function go to section
    */
    goToSection(section: string) {
        this.router.navigate(['/'+section]).then();
    }  

    /**
     * Function to go client portal
     */
    goPortal() {
        const clientPortalUrl = `${environment.portalClientUrl}?preflight=${this.preflight}&companyId=${environment.companyId}&opportunityId=${this.opportunityId}`;
        window.open(clientPortalUrl);
    }

    /*
    * return sum array
    */
    arrayData(data){
        let total: number = 0;

        data.forEach (function(numero){
            total += numero;
        });

        return total;
    }

    /*
    * return array
    */
    takeArray(data) {        
        let arr = []
        for (const key in data) {
            arr.push(data[key])
        }
        return arr;
    }

    /**
     * Function to loader modal
     */
    loaderModal(id, title, text): void {        
        this.modalCardId = id;
        this.modalCardTitle = title;
        this.modalCardText = text;

        //this.viewModalLead = false;

        setTimeout(() => { 
            this.modalService.open(id);
        }, 200);
        
        //TagCommander
        !this.viewModalExit ? !this.homepage ? tC.event.modal_ricontatto_view(this, {}) : '' : tC.event.modal_exitpanel_view(this, {});
        tC.event.modal_info_click(this, { modal: id });       
    }

    /**
     * Function to close modal
     */
    closeModal(id): void {      
        this.modalService.close(id);  

        //TagCommander
        !this.viewModalExit ? !this.homepage ? tC.event.modal_ricontatto_close(this, {}) : '' : tC.event.modal_exitpanel_close(this, {});
    }

    viewModalLeadFunction() {
        this.viewModalLead = true;
        tC.event.cta_contactcenter_click(this, {});
    }

    modalResume(v) {
        this.viewModalResume = v;
    }

    /**
     * Function to Anti Exit modal
     */
    modalAntiExit() {
        const self = this;
        window.addEventListener("mouseout", function (e: any) {
            self.mousePos = 0;
            e = e ? e : window.event;
            var from = e.relatedTarget || e.toElement;
            if ((!from || from.nodeName == "HTML") && screen.width >= self.breakPointMobile && (self.step1 || self.step2) && self.numberAntiExit === 0) {
                self.numberAntiExit++;
                self.viewModalExit = true;
                self.scroll0();                
                setTimeout(() => {
                    self.loaderModal(self.wp.dataModalExit?.id, self.wp.dataModalExit?.title, self.wp.dataModalExit?.content);
                }, 500);
            }
        });
    }
}