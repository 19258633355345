import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WpService } from '@core/services/wp.service';
import { GeneralInfoService } from '@core/services/general-info.service';
import { environment } from '@env/environment';
import { NgxTagCommanderModule, TagCommanderService, WindowRef } from 'ngx-tag-commander';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    template: `
    <router-outlet></router-outlet>`
})

export class AppComponent implements OnInit {

    constructor(
        public router: Router,
        public wp: WpService,
        public generalInfoService: GeneralInfoService,
        private tcService: TagCommanderService,
        private meta: Meta, 
        private title: Title
    ) {}

    ngOnInit() {
        this.loadData(); 

        //Include CANONICAL ONLY IF PROD
        if(Boolean(JSON.parse(environment.production))) {
            this.setCanonicalUrl('https://fotovoltaico.sorgenia.it/');
        }
    }

    async loadData(): Promise<void> {
        try {
            await this.getDataSimulation();
            this.setDataSimulation();
            this.generalInfoService.loaderSimulator = false;
            this.generalInfoService.getTotalLocations();     
            this.generalInfoService.getBasketId();       
            this.generalInfoService.initializeGeneralData();             
        } catch (error) {}
    }

    setDataSimulation() {   
        this.wp.dataCSV = this.wp.data.import_csv_file;   
        this.wp.dataHeader = this.wp.data.header;
        this.wp.dataFooter = this.wp.data.footer;  
        this.wp.dataThemeExtra = this.wp.data.theme_extra;  
        this.wp.dataThemeAdvanced = this.wp.data.theme_advanced;  
        this.wp.dataAddressHeader = this.wp.data.address_header;
        this.wp.dataHomeSection1 = this.wp.data.home_section_1;
        this.wp.dataHomeSection2 = this.wp.data.home_section_2;
        this.wp.dataHomeSection3 = this.wp.data.home_section_3;
        this.wp.dataSimulationStep1 = this.wp.data.simulator_step_1;
        this.wp.dataSimulationStep2 = this.wp.data.simulator_step_2;
        this.wp.dataSimulationStep3 = this.wp.data.simulator_step_3;
        this.wp.dataSimulationStep4 = this.wp.data.simulator_step_4;    
        this.wp.dataForm = this.wp.data.form;
        this.wp.dataThanksYouBanner = this.wp.data.b_thank_you_banner;
        this.wp.dataThanksYouSimple = this.wp.data.b_thank_you_simple;
        this.wp.dataThanksYou = this.wp.data.b_thank_you;
        this.wp.dataLogin = this.wp.data.login;
        this.wp.dataRecoverPassword = this.wp.data.recover_password;
        this.wp.dataCreatePassword = this.wp.data.create_password;
        this.wp.dataError = this.wp.data.error;  
        this.wp.dataModalExit = this.wp.data.modal_antiexit;   
        this.wp.dataTableTags = this.wp.data.table_tags;
        this.wp.dataVariables = this.wp.data.variables;
        this.wp.dataTagCommander = this.wp.data.tagcommander;
        this.wp.dataAdobeTarget = this.wp.data.adobeTarget;
    }

    async getDataSimulation(): Promise<any[]> {          
        const apiWP = this.wp.getDataFields().then(data=> this.wp.data = data);   
        this.generalInfoService.loadContainerTagCommander();   
        return Promise.all([apiWP]);
    }

    setCanonicalUrl(url: string) {
        this.meta.updateTag({ rel: 'canonical', href: url });
    }
}